import { Typography } from '@mui/material'
import { FC } from 'react'
import { useLocation } from 'react-router'
import { useAppSelector } from 'src/hooks'
import { HeaderQuestionAnswer } from 'src/partials'
import { getLayoutIsHeader } from 'src/store/selectors'
import Style from './style.module.scss'

const titleMapper: Record<string, string> = {
  '/sign-up': 'sign up',
  '/sign-in': 'sign in',
  '/forgot-password': 'forgot password',
  '/reset-password': 'reset password'
}

export const Public: FC = () => {
  const location = useLocation()
  const isHeader = useAppSelector(getLayoutIsHeader)

  // const isWatch = useMemo(
  //   () => location.pathname.includes('/watch'),
  //   [location.pathname]
  // )

  // const handleReport = () => {
  //   OverlayService.setOverlay({
  //     open: true,
  //     content: <Report type={EReport.PUBLIC}/>
  //   })
  // }

  if (!isHeader) {
    return null
  }

  const getHeaderTitle = (pathname: string) => {
    return titleMapper[pathname] || ''
  }

  return (
    <div className={Style.publicHeader}>
      <Typography className={Style.Title}>
        {getHeaderTitle(location.pathname)}
      </Typography>

      <div className={Style.actions}>
        <HeaderQuestionAnswer/>
      </div>
    </div>
  )
}
