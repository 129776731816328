import { Avatar, AvatarGroup, MenuItem, Typography } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { CampaignApi } from 'src/api'
import { Button, StyledMenu, Tooltip } from 'src/components'
import { ButtonBlack } from 'src/components/button/styled'
import { useElementSize } from 'src/hooks'
import { IconClose, IconEditRegular, IconGroup, IconMore, IconTrash } from 'src/icons'
import { ICampaignModelExtended } from 'src/interfaces'
import { DeleteConfirmPopup } from 'src/modules/campaign/components/delete-confirm-popup'
import { getCampaignShareLink } from 'src/modules/campaign/utils'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, OverlayService } from 'src/services'
import { stringAvatar, stringGrayAvatar } from 'src/utils'
import { CampaignOwners } from '../campaign-owners'
import Style from './style.module.scss'

const avatarCompanyStyle = { width: '70px', height: '70px', borderRadius: '6px', fontSize: '10px' }

interface IProps {
  htmlId?: string
  campaign: ICampaignModelExtended

  onAfterDelete?: (deletedItem: { id?: number; draftId?: number}) => void
  onTourTrigger?: () => void
  handleOpenCampaign?: (campaign: ICampaignModelExtended) => void
}

const avatarSize = { height: '16px', width: '16px' }
const logoCompany = { height: '70px', width: '70px', borderRadius: '6px' }

export const CardJob: FC<IProps> = ({ htmlId, campaign, onAfterDelete, onTourTrigger, handleOpenCampaign }) => {
  const { id, companyName, companyDomain, jobTitle, logoUrl, submissions = [], draftId, slug } = campaign

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const timerRef = useRef<any>()
  const history = useHistory()

  const goToJob = useCallback((e: any) => {
    e.stopPropagation()
    setAnchorEl(null)

    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      CampaignMutationService.reset()
      return history.push(generate(ERoutes.NEW_CAMPAIGN, { draftId }))
    }

    history.push(generate([ERoutes.CAMPAIGN_DETAIL, { campaignId: id }]))
  }, [onTourTrigger, draftId, history, id])

  const handleClickCard = useCallback(() => {
    // if (onTourTrigger) {
    //   return onTourTrigger()
    // }

    // if (draftId) {
    //   history.push(generate(ERoutes.NEW_CAMPAIGN, { draftId }))
    //   return
    // }
    // history.push(generate([ERoutes.CAMPAIGNS, { id }]))
    if (!handleOpenCampaign) return
    handleOpenCampaign(campaign)
  }, [campaign, handleOpenCampaign])

  const handleOnDelete = useCallback(async () => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      await CampaignApi.deleteDraft(draftId)
      onAfterDelete?.({ draftId })

      OverlayService.reset()
      return
    }

    if (id) {
      await CampaignApi.archive(id)
      onAfterDelete?.({ id })

      OverlayService.reset()
    }
  }, [id, draftId, onAfterDelete, onTourTrigger])

  const handleDelete = useCallback((e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    setAnchorEl(null)

    OverlayService.setOverlay({
      content: (
        <DeleteConfirmPopup
          onCancel={() => OverlayService.reset()}
          onDelete={handleOnDelete}
        />
      ),
      open: true
    })
  }, [handleOnDelete, onTourTrigger])

  const handleCopyLink = useCallback(async (e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    await navigator.clipboard.writeText(getCampaignShareLink(slug))

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    setAnchorEl(null)
  }, [slug, onTourTrigger])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const titleRef = useRef<HTMLSpanElement>(null)
  const [, titleHeight] = useElementSize(titleRef.current)
  const [reached, setReached] = useState(false)
  const [tooltipTitleEnable, setTooltipTitleEnable] = useState(true)
  const handleOwnerTooltipOpen = useCallback(() => {
    setTooltipTitleEnable(false)
  }, [])

  const handleOwnerTooltipClose = useCallback(() => {
    setTooltipTitleEnable(true)
  }, [])

  useEffect(() => {
    try {
      if (!titleRef.current) {
        return
      }

      const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight.slice(0, -2)) // to remove px

      const lineCount = Math.ceil(titleHeight / lineHeight)
      const reached = lineCount > 2

      setReached(reached)
    } catch (error) {
      console.log('[card job] error: ', error)
      setReached(true)
    }
  }, [titleRef, titleHeight])

  const tooltipTitleSx = useMemo(() => {
    if (tooltipTitleEnable) {
      return {}
    }

    return {
      tooltip: Style.popperHidden
    }
  }, [tooltipTitleEnable])

  return (
    <div id={htmlId} className={Style.container}>
      <div className={Style.header}>
        {logoUrl
          ? (
            <Avatar
              variant="square"
              src={logoUrl}
              sx={logoCompany}
            />
          )
          : (
            <Avatar
              {...stringGrayAvatar(companyName, avatarCompanyStyle)}
            />
          )}
        <Tooltip
          title={jobTitle || 'Untitled'}
          placement="top"
          disableFocusListener={!reached}
          disableHoverListener={!reached}
          disableTouchListener={!reached}
          arrow
          classes={tooltipTitleSx}
        >
          <div className="fx flex-column fx-ai-start fx-jc-center ml-3">
            <span className={clsx('body1-bold color-neutral-theme-900', reached && 'twoline-text')} style={{ lineHeight: 1.4 }} ref={titleRef}>
              {jobTitle || 'Untitled'}
            </span>
            <div className="fx fx-ai-center fx-wrap-wrap">
              <AvatarGroup max={3} total={submissions.length}>
                {submissions.slice(0, 3).map((submission, index) => (
                  <Avatar
                    sizes="16"
                    key={index}
                    alt={submission.author?.fullName}
                    src="https://gravatar.com/avatar/27205e5c51cb03f862138b22bcb5dc20f94a342e744ff6df1b8dc8af3c865109?s=16"
                    {...stringAvatar(submission.author?.fullName, avatarSize)}
                  />
                ))}
              </AvatarGroup>
              <span>
                <span className="body2-regular color-neutral-theme-800 fx-shrink-0">
                  {submissions.length} {(submissions.length === 0 || submissions.length > 1) ? 'Candidates' : 'Candidate'}
                </span>
                {true && <span className="body2-bold color-orange-500">&nbsp;&bull; 2 New</span>}
              </span>
            </div>
            <div>
              <span className="body2-regular">Posted Sep 22 </span>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={Style.content}>
        <ButtonBlack className="round-16 px-4 py-0" onClick={handleClickCard}>Details</ButtonBlack>
        <div className="fx flex-row fx-ai-center">
          {companyDomain && id && (
            <CampaignOwners
              id={id}
              onOpen={handleOwnerTooltipOpen}
              onClose={handleOwnerTooltipClose}
              companyDomain={companyDomain}
            />
          )}

          <Button
            order="secondary"
            className={Style.buttonMore}
            onClick={handleOpenMenu}
          >
            <IconMore className={Style.iconMore}/>
          </Button>
          <StyledMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            className={Style.menu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem disableRipple className={Style.menuItem} onClick={goToJob}>
              <IconEditRegular/>
              <Typography variant="body2-regular">Edit</Typography>
            </MenuItem>
            {!draftId && (
              <MenuItem disableRipple className={Style.menuItem} onClick={handleCopyLink}>
                <IconGroup/>
                <Typography variant="body2-regular">Copy Link</Typography>
              </MenuItem>
            )}
            <MenuItem disableRipple className={Style.menuItem} onClick={handleDelete}>
              <IconTrash/>
              <Typography variant="body2-regular">Delete</Typography>
            </MenuItem>
            <MenuItem disableRipple className={Style.menuItem} onClick={handleCopyLink}>
              <IconClose style={{ width: 12, marginLeft: 4 }}/>
              <Typography style={{ marginLeft: 3 }} variant="body2-regular">Close Job</Typography>
            </MenuItem>
          </StyledMenu>
        </div>
      </div>
    </div>
  )
}
