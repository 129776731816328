import { FC, ReactNode } from 'react'
import Styles from './style.module.scss'

interface IProps {
  zIndex?: number
  children: ReactNode
}

export const Overlay: FC<IProps> = (props) => {
  return (
    <div
      style={{ zIndex: props.zIndex || 999 }}
      className={Styles.container}
    >
      {props.children}
    </div>
  )
}
