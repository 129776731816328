import { Box, Skeleton } from '@mui/material'
import { FC } from 'react'
import Styles from './style.module.scss'

const CommentLoading: FC = () => {
  return (
    <Box className={Styles.STContainer}>
      <Skeleton animation="wave" variant="circular" width={48} height={48}/>
      <Box className={Styles.STLeft}>
        <Skeleton animation="wave" height={25} width={100}/>
        <Skeleton animation="wave" height={25} width={400}/>
      </Box>
    </Box>
  )
}

export const CommentLoadingWrapper = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px' }}>
      <CommentLoading/>
      <CommentLoading/>
      <CommentLoading/>
    </Box>
  )
}
