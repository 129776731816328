export enum EReactionTable {
  VIDEO = 'Video'
}

export enum ECandidateReaction {
  UNSURE = 'unsure',
  NOPE = 'nope',
  LIKE = 'like',
  SUPER_LIKE = 'superlike',
  SKIP = 'skip',
  SUPER_NOVA = 'supernova',
  INVITE_APPLY='inviteApply'
}

export enum ECandidateInterviewState {
  INTRO = 0,
  HIRED = 1,
  DISQUALIFIED = 2
}

export enum ESubmissionReaction {
  LIKE = 'like'
}

export enum ESubmissionActions {
  INFO = 'info',
  SHARE = 'share',
  LIKE = 'like',
  DETAIL = 'detail'
}
