const Sorting = () => {
  return (
    <div className="fx fx-ai-center fx-jc-center">

      <div>Sort By: Newest</div>
      <div>Filter: Active</div>

    </div>
  )
}

export default Sorting
