import { IconButton, InputAdornment, SxProps, TextFieldProps } from '@mui/material'
import { FC, HTMLInputTypeAttribute, useCallback, useState } from 'react'
import { IconVisibility, IconVisibilityOff } from 'src/icons'
import { Label } from '../label'
import { ErrorMessage } from '../message'
import { PasswordChecker } from '../password-checker'
import { TextField, TextInput } from './styled'

interface InputBaseProps {
  type?: HTMLInputTypeAttribute
  name?: string
  label?: string
  error?: string
  mb?: number
  sx?: SxProps
  isCheckPassword?: boolean
  labelClassName?: string
  startIcon?: any
  startIconTabIndex?: number
}

type IProps = InputBaseProps & Omit<TextFieldProps, 'error'>

export const Input: FC<IProps> = ({
  label,
  error,
  mb = 4,
  isCheckPassword,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword])

  const type = props.type === 'password' && !showPassword ? 'password' : 'text'
  const InputProps = props.type === 'password'
    ? {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={toggleShowPassword}
            onMouseDown={toggleShowPassword}
          >
            {showPassword ? <IconVisibility/> : <IconVisibilityOff/>}
          </IconButton>
        </InputAdornment>
      ),
      startAdornment: props.startIcon
        ? (
          <InputAdornment position="start">
            <IconButton tabIndex={props.startIconTabIndex}>
              {props.startIcon}
            </IconButton>
          </InputAdornment>
        )
        : null
    }
    : props.InputProps

  return (
    <TextInput mb={mb} sx={props.sx}>
      {label && <Label className={props.labelClassName}>{label}</Label>}
      <TextField
        {...props}
        type={type}
        error={!!error}
        InputProps={InputProps}
      />

      {isCheckPassword
        ? <PasswordChecker password={props.value as string}/>
        : <ErrorMessage className="mt-2">{error}</ErrorMessage>}
    </TextInput>
  )
}
