import { ICampaignModel } from 'src/interfaces'
import { StorageUtils } from './storage.utils'
import { EMotivator } from './user.utils'

export const mapMotivatorToExplanation = (motivator: EMotivator, job?: ICampaignModel): string[] => {
  if (!job) {
    return defaultMotivatorToExplanation(motivator)
  }

  const motivatorObj = job.motivator ?? {}
  return Array.ensure((motivatorObj['quote' + motivator] as unknown as string[]) ?? defaultMotivatorToExplanation(motivator)).slice(0, 1)
}

const MOTIVATORS = [
  EMotivator.ALTRUISTIC,
  EMotivator.CREATIVE,
  EMotivator.COMPETITIVE,
  EMotivator.INDIVIDUALISTIC,
  EMotivator.ECONOMIC,
  EMotivator.LEARNER,
  EMotivator.MISSION_DRIVEN
]

export const isValidMotivators = (val: string): val is typeof MOTIVATORS[number] => {
  console.log(val)
  console.log(MOTIVATORS)

  return MOTIVATORS.includes(val as any)
}

const defaultMotivatorToExplanation = (motivator: EMotivator) => {
  switch (motivator) {
    case EMotivator.ALTRUISTIC:
      return ['Altruistic people drive to help others, are selfless and socially compassionate.']
    case EMotivator.CREATIVE:
      return ['Creative people drive to use imagination and think "outside the box."']
    case EMotivator.COMPETITIVE:
      return ['Competitive people drive to compete and advance their position. They align with power.']
    case EMotivator.INDIVIDUALISTIC:
      return ['Individualistic people strive to be unique. They are mavericks and independent from rank and file.']
    case EMotivator.ECONOMIC:
      return ['Economic people drive to create bottom line value and to make money. They are focused on results.']
    case EMotivator.LEARNER:
      return ['Learners drive to understand and gain knowledge. They solve problems systematically.']
    case EMotivator.MISSION_DRIVEN:
      return ['Mission-Driven people are true to their convictions. They are definitive and tenacious.']
    default:
      return ['']
  }
}

export const mapMotivatorToStyledDescription = (motivator: EMotivator) => {
  switch (motivator) {
    case EMotivator.ALTRUISTIC:
      return '<b style="font-weight: bold; font-size: 1.6rem">Altruistic</b> people drive to help others, are selfless and socially compassionate.'
    case EMotivator.CREATIVE:
      return '<b style="font-weight: bold; font-size: 1.6rem">Creative</b> people drive to use imagination and think "outside the box."'
    case EMotivator.COMPETITIVE:
      return '<b style="font-weight: bold; font-size: 1.6rem">Competitive</b> people drive to compete and advance their position. They align with power.'
    case EMotivator.INDIVIDUALISTIC:
      return '<b style="font-weight: bold; font-size: 1.6rem">Individualistic</b> people strive to be unique. They are mavericks and independent from rank and file.'
    case EMotivator.ECONOMIC:
      return '<b style="font-weight: bold; font-size: 1.6rem">Economic</b> people drive to create bottom line value and to make money. They are focused on results.'
    case EMotivator.LEARNER:
      return '<b style="font-weight: bold; font-size: 1.6rem">Learner</b> drive to understand and gain knowledge. They solve problems systematically.'
    case EMotivator.MISSION_DRIVEN:
      return '<b style="font-weight: bold; font-size: 1.6rem">Mission-Driven</b> people are true to their convictions. They are definitive and tenacious.'
    default:
      return ''
  }
}

export const getMotivatorsLocally = () => {
  return (StorageUtils.getItem<EMotivator[]>('motivators') || []).slice(0, 2)
}

export const setMotivatorsLocally = (motivators: (EMotivator | undefined)[]) => {
  StorageUtils.setItem('motivators', motivators.map(m => m === undefined ? '' : m))
}

export const isMotivatorSetLocally = () => {
  return !!(StorageUtils.getItem<EMotivator[]>('motivators'))
}
