import { CSSProperties, MouseEvent, PropsWithChildren, forwardRef, useCallback } from 'react'
import { useAsRef } from 'src/hooks'
import { STIcon } from './styled'

export interface IconWrapperProps extends PropsWithChildren<any> {
  id?: string
  width?: number
  height?: number
  opacity?: number
  color?: string
  background?: string
  disabled?: boolean
  active?: {
    color?: string
    background?: string
  }
  onClick?: (e: MouseEvent<HTMLElement>) => void
  className?: string
  style?: CSSProperties
  text?: string
}

export const IconWrapper = forwardRef<HTMLDivElement, IconWrapperProps>(({ id, onClick, children, ...props }, ref) => {
  const { text = '' } = props

  const createRipple = useCallback((id: string) => {
    const icon = document.getElementById(id)

    if (!icon) {
      return
    }

    const circle = document.createElement('span')
    const diameter = Math.max(icon.clientWidth, icon.clientHeight)
    circle.style.width = circle.style.height = `${diameter}px`
    circle.classList.add('ripple')

    icon.classList.add('icon-active')
    icon.getElementsByClassName('ripple')[0]?.remove?.()
    icon.appendChild(circle)

    setTimeout(() => {
      icon.classList.remove('icon-active')
    }, 500)
  }, [])

  const onClickRef = useAsRef(onClick)
  const handleClick = useCallback((e: MouseEvent<HTMLElement>) => {
    id && createRipple(id)
    onClickRef.current?.(e)
  }, [createRipple, id, onClickRef])

  return (
    <STIcon ref={ref} id={id} {...props} onClick={handleClick}>
      {children}
      {text}
    </STIcon>
  )
})
