import { FC, useState } from 'react'
import { FeedbackApi } from 'src/api'
import { EMessage } from 'src/enums'
import { useAppSelector } from 'src/hooks'
import { OverlayService, SnackbarService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { getApiErrorMessage } from 'src/utils'
import { Button } from '../button'
import { FilterDropdown } from '../filter-dropdown'
import { Textarea } from '../textarea'
import { reasonOptions } from './helper'
import Style from './style.module.scss'

export const ContactUs: FC = () => {
  const [content, setContent] = useState('')
  const [reason, setReason] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const profile = useAppSelector(getCredentials)
  const [viewOptions, setViewOptions] = useState(reasonOptions)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      if (!reason) {
        SnackbarService.push({
          severity: EMessage.ERROR,
          content: 'Please Provide Your Reason'
        })
        return
      }
      if (!content) {
        SnackbarService.push({
          severity: EMessage.ERROR,
          content: 'Please Provide Your Feedback'
        })
        return
      }

      await FeedbackApi.create({ reason, content, userId: profile?.id })

      OverlayService.reset()
      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Sent'
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleChooseReason = async (option: {
    label: string
    value: string
    isChecked: boolean
    isDefault?: boolean
  }) => {
    const updatedViewOptions = reasonOptions.map((opt) => {
      if (opt.value === option.value) {
        return { ...opt, isChecked: true }
      }
      return opt
    })
    setReason(option.value)
    setViewOptions(updatedViewOptions)
  }

  const onClose = () => {
    OverlayService.reset()
  }

  return (
    <div className={Style.STContainer}>
      <div className={Style.STHeader}>
        <span className={Style.STHeader__Title}>Contact Us</span>
      </div>
      <div className={Style.STBody}>
        <div className={Style.STForm}>
          <div className="fx-column fx-1 gap-2">
            <div className="body2-bold">
              Reason <span className="color-negative-500">*</span>
            </div>
            <FilterDropdown
              options={viewOptions}
              handleClickItem={handleChooseReason}
              mobileLabel="View"
              className={Style.ViewOption}
            />
          </div>

          <div className="body2-bold mt-6">
            Question / Feedback <span className="color-negative-500">*</span>
          </div>
          <Textarea
            placeholder="Ask us anything, anytime..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            autoComplete="off"
            disableResize
            minRows={4}
          />
          <div className="fx-column gap-4 mt-6">
            <Button
              order="black"
              disabled={isSubmitting || (!reason && !content)}
              className="round-16"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              order="secondary"
              className="round-16"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
