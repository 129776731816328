import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { CampaignApi } from 'src/api'
import { Button } from 'src/components'
import { MotivatorBadge } from 'src/components/motivator-badge'
import { useBehaviorMapper } from 'src/hooks'
import { useJobTransformedData } from 'src/hooks/useJobTransformedData'
import { IconBookMark, IconCloseDark } from 'src/icons'
import { ICampaignDetail, ICampaignModel } from 'src/interfaces'
import { CampaignService } from 'src/services'
import { getFullName } from 'src/utils'
import { isValidMotivators } from 'src/utils/motivators.utils'
import { emptyCampaign } from '../../utils'
import { IntroduceYourselfVideo } from '../detail/components/introduce-yoursefl-video'
import Style from './style.module.scss'

interface Props {
  campaign: ICampaignModel
  onClose?: () => void
}
const CampaignDetail = (props: Props) => {
  const { campaign, onClose } = props

  const [campaignDetail, setCampaignDetail] = useState<ICampaignDetail>(emptyCampaign)

  const router = useHistory()
  const dataError = useBehaviorMapper(CampaignService.error)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const parsedJob = useJobTransformedData({ ...campaignDetail, id: campaignDetail.id! })

  const motivators = Object.keys(campaignDetail.motivator ?? {})
    .filter(isValidMotivators)
    .sort((key1, key2) => (campaignDetail.motivator?.[key2] as number) - (campaignDetail.motivator?.[key1] as number))
    .slice(0, 3)

  const handleCampaignDetail = (id: number) => {
    CampaignApi.getOne(id).then(({ data }) => setCampaignDetail(data))
  }

  useEffect(() => {
    handleCampaignDetail(campaign.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.id])

  useEffect(() => {
    if (dataError) {
      router.push({ pathname: '/404' })
    }

    return () => {
      CampaignService.resetError()
    }
  }, [dataError, router])

  return (
    <div id="campaign-detail-container" className={Style.container}>
      <div className="fx fx-ai-center gap-4 px-7 py-5 bg-green-50">
        <IconCloseDark fontSize={20} className="pointer" onClick={onClose}/>
      </div>

      <div className="fx gap-6 p-7" style={{ marginBottom: '72px' }}>
        <div className={Style.video}>
          <IntroduceYourselfVideo
            disableUpdateVideo
            uploadVideoUrl={campaignDetail.uploadVideoUrl}
          />
        </div>

        <div className="fx-1 fx-column gap-6">
          {!!campaignDetail.author && (
            <div className={clsx(Style.borderBottom, 'fx fx-column pb-2')}>
              <span className={Style.fullname}>{getFullName(campaignDetail.author)}</span>
              <span className={Style.userRole}>Chefk Technical Officer</span>
            </div>
          )}
          <div className="fx-column gap-2">
            <span className={Style.jobTitle}>{campaignDetail.jobTitle}</span>
            <div className="fx fx-wrap-wrap gap-1">
              {motivators.map((m) => (
                <MotivatorBadge key={m} small value={m}/>
              ))}
            </div>
          </div>
          <div className="fx-column gap-2">
            <div className="fx fx-ai-center gap-2 fs-12">
              {campaignDetail.logoUrl && (
                <img
                  alt="logo"
                  src={campaignDetail.logoUrl}
                  width={16}
                  height={16}
                  className="round-10"
                />
              )}
              <span className="meta-bold neutral-800">{campaignDetail.companyName}</span>
              <span className="meta-regular neutral-800">{campaignDetail.location}</span>
            </div>
            {parsedJob.salary && (
              <div className="meta-regular neutral-N100">
                {parsedJob.salary}
                {parsedJob.salaryRate}
              </div>
            )}
          </div>
          <div className={clsx('fx gap-3', Style.actionButtons)}>
            <Button
              className="round-16"
              kmSize="medium"
              order="black"
              disabled
            >
              Apply
            </Button>
            <Button disabled>
              <IconBookMark color="currentColor"/>
            </Button>
          </div>
        </div>
      </div>

      <div className="fx-column gap-3 p-7 pt-1">
        <div className="fx-column">
          <span className="body1-bold color-neutral-theme-800">About The Job</span>
        </div>
        {campaignDetail.jobSummary && <div className="meta-regular color-neutral-theme-800">{campaignDetail.jobSummary}</div>}
      </div>
    </div>
  )
}

export default CampaignDetail
