import { styled, Tab, Tabs } from '@mui/material'

export const STContainer = styled('div', {
  label: 'Container'
})(
  ({ theme }) => `
  position: relative;
  height: 52px;
  background-color: ${theme.colors['--color-neutral-theme-100']};

  & .MuiTabs-root {
    width: 100% !important;
  }
`
)

export const STTabs = styled(Tabs, {
  label: 'Tabs'
})(
  ({ theme }) => `
  position: relative;
  width: fit-content;
  height: 52px;
  padding: 0 4px;
  background-color: ${theme.colors['--color-white']};

  & .MuiTabs-flexContainer {
    height: 52px;
    gap: 22px;
  }

  & .MuiTabs-indicator {
    bottom: 4px;
    height: 44px;
    border: 0;
    font-size: 16px;
    line-height: 28px;
    color: ${theme.colors['--color-neutral-theme-50']};
    border-radius: 25px;
    background-color: ${theme.colors['--color-white']};
  }

  & .MuiTabScrollButton-root.Mui-disabled {
    opacity: 1;
  }
`
)

export const STTab = styled(Tab, {
  label: 'Tab'
})(
  ({ theme }) => `
    height: 52px;
    padding: 0;
    color: ${theme.colors['--color-neutral-theme-700']};
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-transform: unset;
    opacity: 1;
    flex-basis: unset;
    border-bottom: 5px solid ${theme.colors['--color-white']};
    transition: all 0.3s ease-in-out;


  &.Mui-selected {
    z-index: 1;
    font-weight: 600;
    border-bottom: 5px solid ${theme.colors['--color-neutral-theme-700']};
  }
`
)
