import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation } from 'src/hooks'
import { Logo } from 'src/images'
import { EAuthView } from 'src/interfaces'
import { setNameSchema } from 'src/validation'
import { Header } from '../../../header'
import Style from './style.module.scss'

interface ISetName {
  firstName: string
  lastName: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  handleChangeFormData: (name: string, value: TSignUpPayload[keyof TSignUpPayload]) => void
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onChangeView: () => void
}

export const SetName: FC<IProps> = ({ handleChangeFormData, onChangeView }) => {
  const [formValues, setFormValues] = useState<ISetName>({ firstName: '', lastName: '' })
  const { errors, validateAt } = useOldValidation<ISetName>()
  const { eventHandler } = useAnalytic('signup_name')
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: setNameSchema,
      path: name as keyof ISetName,
      data: { ...formValues, [name]: value }
    })
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, setFormValues, formValues])

  const onNext = (e: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    handleChangeFormData('firstName', formValues.firstName)
    handleChangeFormData('lastName', formValues.lastName)
    onChangeView()
  }

  const isInValid = !formValues.firstName || !formValues.lastName || errors.firstName || errors.lastName

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>

        <div className={Style.MainSide}>
          <div className={Style.STSetPasswordWrapper}>
            <form className={Style.STForm} onSubmit={onNext}>
              <div className={Style.Logo}>
                <img src={Logo} alt="logo"/>
              </div>
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Sign Up to KNOWME<span>TM</span></Typography>
                <Typography align="center" className={Style.Description}>
                  Hiring
                </Typography>
              </div>

              <div className={Style.FormInput}>
                <Input
                  label="First name"
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formValues.firstName || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_NAME)}
                  error={errors.firstName}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                />
                <Input
                  label="Last name"
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formValues.lastName || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_NAME)}
                  error={errors.lastName}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                />

                <Button
                  type="submit"
                  className="round-16"
                  order="black"
                  kmSize="medium"
                  disabled={!!isInValid || loading}
                  onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT)}
                >
                  Next
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
