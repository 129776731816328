
import { FC } from 'react'
import { useHistory } from 'react-router'
import { ButtonBlack, ButtonSecondary } from 'src/components/button/styled'
import { IconCreateJob, IconInvite, IconLike } from 'src/icons'
import { OverlayService } from 'src/services'
import Style from './style.module.scss'

export enum UnAuthActions {
  Like = 'Like',
  Invite = 'Invite',
  CreateJob = 'CreateJob'
}

interface IProps {
  action: UnAuthActions
}

export const SignUpModalV2: FC<IProps> = (props) => {
  const { action } = props
  const history = useHistory()

  const SignInOrSignUp = () => {
    return (
      <>
        <br/>
        <span className="body2-bold">sign up</span> or <span className="body2-bold">sign in</span>
      </>
    )
  }

  const getDescription = (action: UnAuthActions) => {
    const mapper = {
      [UnAuthActions.Like]: <>To like a vibe check, please <SignInOrSignUp/></>,
      [UnAuthActions.Invite]: <>To invite a candidate to apply, please <SignInOrSignUp/></>,
      [UnAuthActions.CreateJob]: <>To create a job, please <SignInOrSignUp/></>
    }
    return mapper[action] ?? ''
  }

  const getImage = (action: UnAuthActions) => {
    const mapper = {
      [UnAuthActions.Like]: <IconLike/>,
      [UnAuthActions.Invite]: <IconInvite/>,
      [UnAuthActions.CreateJob]: <IconCreateJob/>
    }
    return mapper[action] ?? ''
  }

  const onHandleNext = (click: 'signup' | 'signin') => () => {
    OverlayService.reset()
    if (click === 'signup') {
      history.push('/sign-up')
    } else if (click === 'signin') {
      history.push('/sign-in')
    }
  }

  return (
    <div className={Style.container}>
      <div className="fx flex-column fx-ai-center gap-14px">
        {getImage(action)}
      </div>

      <div className="fx flex-column">
        <h5 className="my-2"> Almost there!</h5>
        <div className="body2-regular line-he">
          {getDescription(action)}
        </div>
      </div>

      <div className="fx flex-column">
        <ButtonBlack className="round-16 mb-4" onClick={onHandleNext('signup')}>
          Sign Up
        </ButtonBlack>
        <ButtonSecondary className="round-16" onClick={onHandleNext('signin')}>
          Sign In
        </ButtonSecondary>
      </div>
    </div>
  )
}
