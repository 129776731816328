import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { IconWrapper, Tooltip, useAnalytic } from 'src/components'
import { ECandidateReaction, ETrackingEvent } from 'src/enums'
import { IconInviteApply, IconLikes, IconNope } from 'src/icons'
import { SignUpModalV2, UnAuthActions } from 'src/partials/sign-up-modal-v2'
import { OverlayService } from 'src/services'
import { IIcon } from '../../types/types.vibes'
import Style from './style.module.scss'

type VideoActionsProps ={
  videoId: number
  vertical?: boolean
}
const VideoActions = (props: VideoActionsProps) => {
  const { videoId, vertical } = props
  const theme = useTheme()

  const { eventHandler } = useAnalytic('')

  const icons: IIcon[] = [
    {
      title: 'Like',
      event: ETrackingEvent.BTN_VIDEO_LIKE,
      action: ECandidateReaction.LIKE,
      icon: IconLikes,
      color: theme.colors['--color-neutral-theme-800'],
      text: vertical ? <></> : <span className="pl-2 color-neutral-theme-800">Like</span>,
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-neutral-theme-200'] }
    },
    {
      title: 'Invite to apply',
      event: ETrackingEvent.BTN_VIDEO_INVITE_APPLY,
      action: ECandidateReaction.INVITE_APPLY,
      icon: IconInviteApply,
      color: theme.colors['--color-neutral-theme-800'],
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-neutral-theme-200'] }
    },
    {
      title: 'Nope',
      event: ETrackingEvent.BTN_VIDEO_TRASH,
      action: ECandidateReaction.NOPE,
      icon: IconNope,
      color: theme.colors['--color-neutral-theme-800'],
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-neutral-theme-200'] }
    }
  ]

  const handleReaction = (action: ECandidateReaction) => {
    const toUnAuthAction: Record<string, UnAuthActions> = {
      [ECandidateReaction.LIKE]: UnAuthActions.Like,
      [ECandidateReaction.INVITE_APPLY]: UnAuthActions.Invite
    }
    const unAuthAction = toUnAuthAction[action]
    if (!unAuthAction) {
      return
    }
    OverlayService.setOverlay({
      content: <SignUpModalV2 action={unAuthAction}/>,
      open: true
    })
  }

  return (
    <div
      className={clsx(Style.videoActions, { [Style.vertical]: vertical })}
    >
      {icons.map(({ title, text, action, event, icon: Icon, color, active }) => (
        <IconWrapper
          key={title}
          id={title}
          width="max-content"
          height={32}
          color={color}
          active={active}
          className={Style.icon}
          text={text}
          style={{ padding: '14px 8px' }}
          onClick={eventHandler({
            key: event,
            contextData: { videoId }
          }, () => handleReaction(action))}
        >
          <Tooltip title={title}>
            <Icon width={16} height={16}/>
          </Tooltip>
        </IconWrapper>
      ))}
    </div>
  )
}

export default VideoActions
