import { object, string } from 'yup'

export const schema = object().shape({
  reason: string().required('You need to select a reason.'),
  content: string().required('You need to enter your feedback.')
})

export const reasonOptions = [
  {
    label: 'Account',
    value: 'Account',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Jobs',
    value: 'Jobs',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Video Vibe Check',
    value: 'Video Vibe Check',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Quiz',
    value: 'Quiz',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Messaging',
    value: 'Messaging',
    isChecked: false,
    isDefault: false
  },
  {
    label: 'Other',
    value: 'Other',
    isChecked: false,
    isDefault: false
  }
]
