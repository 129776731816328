import qs, { IStringifyOptions } from 'qs'
import { RegexConsts } from 'src/constants'
import { ESalaryRate } from 'src/enums'
import { ValidationError } from 'yup'

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

type TTimeUnit =
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
type TTimeUnitShort = 'y' | 'm' | 'w' | 'd' | 'h' | 'min' | 'sec'

const unitMap: Record<TTimeUnit, TTimeUnitShort> = {
  year: 'y',
  month: 'm',
  week: 'w',
  day: 'd',
  hour: 'h',
  minute: 'min',
  second: 'sec'
}

interface SalaryData {
  salaryValue: {
    min?: number
    max?: number
  }
  salaryRate?: string
}

/**
 * alphabeticalSort option of qs module
 * @param {String} a
 * @param {String} b
 */
export const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b)
}

// =================================================================================
/**
 * Check input is Object or not
 * @param {Any} obj
 * @return {Boolean}
 */
export const isObject = <T>(obj: T): boolean =>
  obj && typeof obj === 'object' && !Array.isArray(obj)

// =================================================================================
/**
 * Valid input is an Object
 * @param {Any} arr
 * @return {Object}
 */
export const ensureObject = <T, D>(obj: T, defaultValue?: D) =>
  isObject(obj) ? obj : isObject(defaultValue) ? defaultValue : {}

// =================================================================================
/**
 * Valid input is an Object
 * @param {Any} arr
 * @return {Object}
 */
export const ensureArray = <T>(array: T) =>
  Array.isArray(array) ? array : []

// =================================================================================
/**
 * Compare two object, true if match
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Boolean}
 */
export const compareObj = <T, K>(obj1: T, obj2: K): boolean => {
  const options: IStringifyOptions = {
    arrayFormat: 'repeat',
    sort: alphabeticalSort
  }
  return (
    qs.stringify(ensureObject(obj1), options) ===
    qs.stringify(ensureObject(obj2), options)
  )
}

// ------------------------------------------------------------------------------------

export const convertErrorYup = <T>(error: ValidationError): T => {
  const errors: T = {} as T
  error.inner.forEach((err: ValidationError) => {
    if (err.path && !errors[err.path as keyof T]) {
      (errors as any)[err.path] = err.message
    }
  })
  return errors
}

export const shortenFilename = (filename?: string, length = 20) => {
  if (!filename) return filename
  if (filename.length < length) return filename
  return `${filename.slice(0, length)}...`
}

export const safeParseUrl = (url?: string) => {
  try {
    if (!url) {
      return url
    }

    if (!url.startsWith('http') || !url.startsWith('https')) {
      url = `https://${url}`
    }

    const urlObj = new URL(url)
    return urlObj.toString()
  } catch (error) {
    return url
  }
}

export function formatMoney(value = 0, currency?: string) {
  const moneyValue = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return currency ? `${currency}${moneyValue}` : moneyValue
}

export function truncateText(text: string, maxLength: number, lastChars = 0) {
  const ellipsis = '...'

  if (text.length <= maxLength) {
    return text // No truncation needed
  }

  const firstChars = lastChars ? maxLength - ellipsis.length - lastChars : Math.ceil((maxLength - ellipsis.length) / 2)
  const _lastChars = lastChars || Math.floor((maxLength - ellipsis.length) / 2)

  const truncatedText = text.substr(0, firstChars) + ellipsis + text.substr(text.length - _lastChars)

  return truncatedText
}

export const isValidWebsiteUrl = (url?: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return url && !!pattern.test(url)
}

export const isEmptyBriefDescription = (str?: string) => {
  const regex = /^(<p><br><\/p>)*$/
  return regex.test(str || '')
}

export const decodeJWTPayload = (jwtToken: string) => {
  try {
    const encodedPayload = jwtToken.split('.')[1]
    const payloadString = window.atob(encodedPayload)
    return JSON.parse(payloadString)
  } catch (err) {
    console.log('failed to decode base 64 of jwt token', err)
    return {}
  }
}

export const validateFullName = (fullname = '') => {
  const trimmedFullname = fullname.trim()
  return trimmedFullname.length > 0 && RegexConsts.FULL_NAME.test(trimmedFullname)
}

export const convertToShortenedText = (
  timeAgo: string,
  isHiddenText?: boolean
): string => {
  const regex = /(\d+)\s+(year|month|week|day|hour|minute|second)s?/
  const match = timeAgo.match(regex)

  if (RegexConsts.AN_HOUR_AGO.test(timeAgo)) {
    return isHiddenText ? '1h' : '1h ago'
  }

  if (RegexConsts.A_MINUTE_AGO.test(timeAgo)) {
    return isHiddenText ? '1min' : '1min ago'
  }

  if (RegexConsts.A_FEW_SECONDS_AGO.test(timeAgo)) {
    return isHiddenText ? '1min' : '1min ago'
  }

  if (!match) {
    if (RegexConsts.A_MONTH_AGO.test(timeAgo)) {
      return isHiddenText ? '1m' : '1m ago'
    }

    if (RegexConsts.A_WEEK_AGO.test(timeAgo)) {
      return isHiddenText ? '1w' : '1w ago'
    }

    if (RegexConsts.A_DAY_AGO.test(timeAgo)) {
      return isHiddenText ? '1d' : '1d ago'
    }

    return timeAgo
  }

  const numericValue = parseInt(match[1], 10)
  const unit = match[2] as TTimeUnit
  const shortUnit = unitMap[unit]

  if (isHiddenText) {
    return `${numericValue}${shortUnit}`
  }
  return `${numericValue}${shortUnit} ago`
}

export const convertSalaryToPerYear = (data: SalaryData): string => {
  const { salaryValue, salaryRate = ESalaryRate.PER_YEAR } = data
  let minSalary: number = salaryValue?.min ?? 0

  if (salaryRate === 'perHour') {
    minSalary *= 40 * 52
  } else if (salaryRate === 'perMonth') {
    minSalary *= 12
  }

  return `${minSalary.toLocaleString('en-US')}/year`
}

export const formatSalary = (data: SalaryData): string => {
  const { salaryValue, salaryRate = ESalaryRate.PER_MONTH } = data
  const minSalary: number = salaryValue?.min ?? 0
  let displayRate = 'month'

  switch (salaryRate) {
    case ESalaryRate.PER_HOUR:
      displayRate = 'hour'
      break
    case ESalaryRate.PER_MONTH:
      displayRate = 'month'
      break
    case ESalaryRate.PER_YEAR:
      displayRate = 'year'
      break
    default:
      displayRate = 'month'
      break
  }

  return `${minSalary.toLocaleString('en-US')}/${displayRate}`
}

export const convertEquivalentSalaryRate = (value?: ESalaryRate) => {
  switch (value) {
    case ESalaryRate.PER_HOUR:
      return 'hour'

    case ESalaryRate.PER_MONTH:
      return 'month'

    case ESalaryRate.PER_YEAR:
      return 'year'

    default:
      return 'year'
  }
}

export const formatNumber = (num: number) => {
  return formatter.format(num)
}
