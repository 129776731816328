import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { ComponentProps, FC } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconSendMail } from 'src/icons'
import { Header } from '../header'
import Style from './success.module.scss'

interface IProps {
  email: string
  onSubmit: ComponentProps<'form'>['onSubmit']
}

export const Success: FC<IProps> = ({ onSubmit, email }) => {
  const { eventHandler } = useAnalytic('pswd_reset2')
  const theme = useTheme()

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>

      <div className={Style.Container}>
        <div className={Style.MainSide}>
          <Box className={Style.STContainer}>
            <div className={Style.SectionSendMail}>
              <IconSendMail className={Style.iconEmailLarge}/>
              <Typography variant="h4">check your email</Typography>
              <Typography
                className={Style.subText}
                variant="body1-medium"
                color={theme.colors['--color-neutral-theme-300']}
              >
                We sent you a password reset link to <span className={Style.Email}>{email}</span>.
                Click the link to reset your password.
              </Typography>
              <Typography
                variant="body1-regular"
                className={Style.resendEmail}
                color={theme.colors['--color-neutral-theme-300']}
              >
                Didn’t receive the email?
                <br/>
                Check your spam folder or
                <Typography
                  component="a"
                  variant="body1-regular"
                  sx={{ cursor: 'pointer' }}
                  onClick={eventHandler(ETrackingEvent.BTN_RESEND_RESET_PSWD_LINK, onSubmit)}
                >
                  {' '} click to resend.
                </Typography>
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    </>
  )
}
