export const RE_URL_WITH_OUT_PROTOCOL = /^((http|https)?:\/\/)?([\\da-zA-Z1-9.-]+)\.([a-zA-Z.]{2,6})[/\w .-]*\/?(.*)?$/

export const RegexConsts = {
  FULL_NAME: /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  LINKEDIN_URL: /linkedin\.com\/(.{1,})/,
  PHONE_NUMBER: /^(?:(?:\+1)?[-.\s]?)?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/,
  SHARING_LABEL: /^[a-zA-Z0-9]{1,20}$/,
  A_FEW_SECONDS_AGO: /a\s+few\s+seconds\s+ago/,
  A_MINUTE_AGO: /a\s+minute\s+ago/,
  AN_HOUR_AGO: /an\s+hour\s+ago/,
  A_MONTH_AGO: /a\s+month\s+ago/,
  A_WEEK_AGO: /a\s+week\s+ago/,
  A_DAY_AGO: /a\s+day\s+ago/,
  WITHOUT_SPECIAL_CHARACTERS: /[^A-Za-z0-9]/g,
  WITHOUT_SPECIAL_CHARACTERS_N_NUMERICS: /[^A-Za-z -]/g,
  WITHOUT_FIRST_SPACE_CHAR: /^-\s+/g,
  HTTP: /^http:\/\/|^https:\/\//,
  WEBSITE: new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
}
