import { Box, Collapse, IconButton } from '@mui/material'
import clsx from 'clsx'
import * as H from 'history'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { match as Match, NavLink } from 'react-router-dom'
import {
  NoTabletOrMobile,
  TabletAndMobile,
  useAnalytic
} from 'src/components'
import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import { PUBLIC_VIBE_BASE_PATH } from 'src/constants/vibe'
import { ETrackingEvent } from 'src/enums'
import { useAppSelector, useBehaviorMapper, useShowRedDot } from 'src/hooks'
import {
  IconChevronLeft,
  IconHome,
  IconHorizontalLinesBold,
  IconLogo,
  IconMarketplace,
  IconMarketplaceFilled,
  IconTalent
} from 'src/icons'
import { HeaderNotificationBadge } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { ERecordFor, NavigationService } from 'src/services'
import {
  getGuide,
  getIsAuthenticated,
  getLayoutIsAside
} from 'src/store/selectors'
import { Item } from './components/item'
import { SubItem } from './components/sub-item'
import { UserMenu } from './components/user-menu'
import { VVCAd } from './components/vvc-ad'
import { bottomMenuConfig, menuConfig } from './menu-config'
import Style from './style.module.scss'

interface IProps {
  onDrawerClose?: () => void
}

const PATHNAME_INGORE_AD = ['/campaigns/new']
const pattern = /^\/campaigns\/\d+$/

export const Aside: FC<IProps> = (props) => {
  const history = useHistory()
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const isAside = useAppSelector(getLayoutIsAside)
  const { eventHandler } = useAnalytic('')
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const guide = useAppSelector(getGuide)

  const [isMinimized, setIsMinimized] = useState(false)
  const [isKnowmeMarketExpanded, setIsKnowmeMarketExpanded] = useState(false)
  const [isKnowmeMarketActive, setIsKnowmeMarketActive] = useState(false)

  const [routeActive, setRouteActive] = useState<string | null>(null)
  const showRedDot = useShowRedDot()
  const firstUploadedPfv = useBehaviorMapper(NavigationService.firstUploadedPfv$)

  const [shouldShowVVAd, setShouldShowVVCAd] = useState(false)

  // const goToSignIn = useCallback(() => {
  //   OverlayService.setOverlay({
  //     content: <SignUpModal showCandidateText/>,
  //     open: true
  //   })
  // }, [])

  const handleActiveRoute = useCallback((route: string) => {
    if (timeout.current) clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      setRouteActive(route)
    }, 100)
  }, [])

  const handleIsActive = useCallback(
    (match: Match | null, location: H.Location<any>, route: string) => {
      if (match) {
        handleActiveRoute(route)
      }

      if (location.pathname.includes(PUBLIC_CAMPAIGN_BASE_PATH)) {
        handleActiveRoute('/campaigns')
      }

      if (location.pathname === '/' && route === '/vibes') {
        handleActiveRoute('/vibes')
      }
      if (location.pathname === '/' && route === PUBLIC_VIBE_BASE_PATH) {
        handleActiveRoute(PUBLIC_VIBE_BASE_PATH)
      }

      if (location.pathname.includes(PUBLIC_CAMPAIGN_BASE_PATH)) {
        handleActiveRoute('/campaigns')
      }

      return false
    },
    [handleActiveRoute]
  )

  useEffect(() => {
    // listen change history
    const unregister = history.listen((location) => {
      if (!menuConfig.some(({ route }) => route === location.pathname)) {
        setRouteActive(null)
      }

      props.onDrawerClose?.()
    })

    return () => {
      unregister()
    }
  }, [history, props])

  const handleLinkClick = (e: any) => {
    if (isAuthenticated) {
      return
    }
    history.push(generate(ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING))
  }

  const handleCloseVVCAd = useCallback(() => {
    setShouldShowVVCAd(false)
  }, [])

  const goToRecorderPage = () => {
    history.push(generate(ERoutes.RECORDING, { type: ERecordFor.PFV }), { onlyRecordVideo: true })
  }

  useEffect(() => {
    console.log({ routeActive })

    if (bottomMenuConfig.some(({ route }) => route === routeActive)) {
      setIsKnowmeMarketExpanded(true)
      setIsKnowmeMarketActive(true)
    } else {
      setIsKnowmeMarketActive(false)
      setIsKnowmeMarketExpanded(false)
    }
  }, [routeActive])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [timeout])

  const handleKnowmeMarketClicked = (e: any) => {
    setIsKnowmeMarketExpanded(prev => !prev)
    // if (!isAuthenticated) {
    //   e.preventDefault()

    //   return OverlayService.setOverlay({
    //     content: <SignUpModal showCandidateText/>,
    //     open: true
    //   })
    // }
    // setTimeout(() => {
    //   if (firstUploadedPfv.uploaded) {
    //     NavigationService.setShowRedeemCode(true, CREDIT_FOR_FIRST_TIME_VVC)
    //   }
    // }, 1000)
  }

  // if create new campaign or edit/detail campaign => won't show vvc ad
  const isIncludePathname = useMemo(() => {
    return (
      !PATHNAME_INGORE_AD.includes(history.location.pathname) &&
      !pattern.test(history.location.pathname)
    )
  }, [history.location.pathname])

  useEffect(() => {
    if (isAuthenticated && isIncludePathname) {
      if (guide?.campaignId) {
        setShouldShowVVCAd(false)
      } else {
        if (guide?.campaignId === null && !guide?.firstTimeUploadPfv) {
          setShouldShowVVCAd(true)
        } else {
          setShouldShowVVCAd(!guide?.firstTimeUploadPfv)
        }
      }
    } else {
      setShouldShowVVCAd(false)
    }
  }, [
    guide?.campaignId,
    guide?.firstTimeUploadPfv,
    isAuthenticated,
    isIncludePathname
  ])

  return (
    <div
      className={clsx(
        Style.aside,
        { 'd-none': !isAside },
        isMinimized && Style.asideMinimized
      )}
    >
      <Box>
        <NoTabletOrMobile>
          <div className="fx flex-row fx-ai-center px-2 h-16">
            <div className={Style.asideLogo}>
              <IconLogo/>
            </div>
            <div className={Style.ChevronIcon}>
              <IconChevronLeft
                onClick={() => setIsMinimized((prev) => !prev)}
                className={clsx(
                  'pointer hover-svg-color-neutral-theme-50',
                  isMinimized && 'rotate-180'
                )}
              />
            </div>
          </div>
        </NoTabletOrMobile>

        <TabletAndMobile>
          <div className={Style.asideTop}>
            <IconButton onClick={props.onDrawerClose}>
              <IconHorizontalLinesBold color="#fff"/>
              {showRedDot && <span className={Style.redDot}/>}
            </IconButton>

            <HeaderNotificationBadge inverse/>
          </div>
        </TabletAndMobile>

        <div className="fx flex-column gap-18px mt-15">
          <NavLink
            to="/campaigns"
            onClick={eventHandler(
              ETrackingEvent.BTN_MY_JOBS_SCREEN,
              handleLinkClick
            )}
            isActive={(match, location) =>
              handleIsActive(match, location, '/campaigns')}
            className="w-100 fx fx-jc-center"
          >
            <Item
              title="My Jobs"
              icon={IconHome}
              activeIcon={IconHome}
              isActive={routeActive === '/campaigns'}
              isMinimized={isMinimized}
            />
          </NavLink>

          <div className={Style.marketPlaceWrapper}>
            {isAuthenticated
              ? (
                <>
                  <NavLink
                    to="/vibes"
                    onClick={handleKnowmeMarketClicked}
                    className="w-100 fx fx-jc-center"
                  >
                    <Item
                      title="Talent"
                      icon={IconMarketplace}
                      activeIcon={IconMarketplaceFilled}
                      isActive={isKnowmeMarketActive}
                      isExpand={isKnowmeMarketExpanded}
                      isMinimized={isMinimized}
                      customMinimized={TalentMinimized}
                      showEarnMoney={!firstUploadedPfv.isAtKnowmeTalent && firstUploadedPfv.uploaded}
                    />
                  </NavLink>
                  <Collapse in={isKnowmeMarketExpanded}>
                    <div className="fx flex-column gap-4 w-fit-content">
                      {bottomMenuConfig.map(
                        ({
                          id,
                          route,
                          event,
                          icon,
                          activeIcon,
                          title,
                          watchNoticeKey
                        }) => {
                          return (
                            <NavLink
                              key={route}
                              className={!isAuthenticated ? Style.disable : ''}
                              to={route}
                              onClick={eventHandler(event, handleLinkClick)}
                              isActive={(match, location) =>
                                handleIsActive(match, location, route)}
                            >
                              <SubItem
                                title={title}
                                id={id}
                                icon={icon}
                                isActive={routeActive === route}
                                activeIcon={activeIcon}
                                noticeBadgeKey={watchNoticeKey}
                                isMinimized={isMinimized}
                              />
                            </NavLink>
                          )
                        }
                      )}
                    </div>
                  </Collapse>
                </>
              )
              : (
                <NavLink
                  to={PUBLIC_VIBE_BASE_PATH}
                  isActive={(match, location) => handleIsActive(match, location, PUBLIC_VIBE_BASE_PATH)}
                  onClick={handleKnowmeMarketClicked}
                  className="w-100 fx fx-jc-center"
                >
                  <Item
                    title="Talent"
                    icon={IconTalent}
                    activeIcon={IconTalent}
                    isActive={routeActive === PUBLIC_VIBE_BASE_PATH}
                    isMinimized={isMinimized}
                    showEarnMoney={!firstUploadedPfv.isAtKnowmeTalent && firstUploadedPfv.uploaded}
                  />
                </NavLink>
              )}
          </div>
        </div>
      </Box>

      <Box mt={5}>
        {shouldShowVVAd && !isMinimized && (
          <VVCAd onOk={goToRecorderPage} onClose={handleCloseVVCAd}/>
        )}

        {isAuthenticated && <UserMenu isMinimized={isMinimized}/>}
      </Box>
    </div>
  )
}

const TalentMinimized: FC<{ className?: string; onClick?: () => void }> = (
  props
) => {
  return (
    <span
      onClick={props.onClick}
      className={clsx('pointer body1-bold', props.className)}
    >
      Talent
    </span>
  )
}
