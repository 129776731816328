import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { EEmptyVideoVariant, Traits, VideoPlayer } from 'src/components'
import { EMessage } from 'src/enums'
import { useAppDispatch, useAppSelector, useDebounce, useElementSize, useUnsubscribe } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { NavigationService, SnackbarService } from 'src/services'
import { setLayoutAside, setLayoutLoading, setLayoutPageComponent, setLayoutPageTitle, setLayoutShouldShowLoading } from 'src/store/actions'
import {
  getIsAuthenticated,
  getLayoutIsLoading
} from 'src/store/selectors'
import { getApiErrorMessage, getVideoSource } from 'src/utils'
import { EmptyVibes } from '../empty-vibes'
import VideoActions from '../video-actions'
import ViewMode from '../view-mode'
import Style from './style.module.scss'

const videoPlayerSx = {
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  aspectRatio: '445 / 787',
  height: 'unset',
  backgroundColor: 'black'
}

export const GuestVibes: FC = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const unsubscribe$ = useUnsubscribe()
  const location = useLocation<{from: string}>()
  const history = useHistory()
  const isLoading = useAppSelector(getLayoutIsLoading)
  const [isPlay] = useState(false)
  const [candidate, setCandidate] = useState<ICandidateModel>()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/vibes')
    }
  }, [isAuthenticated, history])

  const playedMapRef = useRef<Map<number, boolean>>(new Map([]))
  const handleWatchedVideo = useCallback(async (videoId: number, playing: boolean) => {
    try {
      if (!playing) {
        return
      }

      if (playedMapRef.current.get(videoId)) {
        return
      }

      playedMapRef.current.set(videoId, true)
      // await CandidateApi.play(videoId)
    } catch (error) {
      console.log({ error })
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'NETWORK ERROR'
      })
    }
  }, [])

  useEffect(() => {
    if (location?.state?.from) {
      history.push(location?.state?.from)
    }
  }, [history, location?.state?.from])

  useEffect(() => {
    if (candidate?.video?.id) {
      handleWatchedVideo(candidate.video.id, isPlay)
    }
  }, [handleWatchedVideo, candidate?.video?.id, isPlay])

  const loadVibes = useCallback(() => {
    const promise = CandidateApi.getPublicOne()

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe(({ data }) => {
        setCandidate(data)
      })
  }, [dispatch, unsubscribe$])

  useDebounce(() => {
    loadVibes()
  }, 300, [loadVibes])

  useEffect(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutPageTitle('Vibes'))
    dispatch(setLayoutPageComponent(null))
    dispatch(setLayoutShouldShowLoading(false))
    dispatch(setLayoutLoading(true))

    NavigationService.setIsAtKnowmeTalent(true)

    return () => {
      dispatch(setLayoutShouldShowLoading(true))
      NavigationService.setIsAtKnowmeTalent(false)
    }
  }, [dispatch])

  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)

  const vibeWrapperStyle = useMemo(() => {
    return {
      aspectRatio: '445 / 851',
      height: 'calc(100vh - 244px)'
      // [(elWidth / elHeight) > (445 / 851) ? 'height' : 'width']: '100%'
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elWidth, elHeight])

  const renderVibe = () => {
    if (!candidate) {
      return null
    }

    return (
      <ViewMode viewMode="swipe">
        <VideoPlayer
          id="video"
          className={Style.videoPlayer}
          videoId={candidate.video?.id}
          tracks={candidate.video?.tracks}
          trackingEvent
          isPlay={isPlay}
          image={candidate.video?.urlVideoImageThumbnail}
          animatedImage={candidate.video?.urlVideoAnimatedImage}
          url={getVideoSource(candidate.video)}
          onPlayingChange={(playing) => handleWatchedVideo(candidate.video.id, playing)}
          mimeType={candidate.video?.internalSourceMetadata?.mimeType}
          style={videoPlayerSx}
        />

        <div className={clsx('relative fx fx-1 fx-ai-center fx-jc-center', Style.bottomBar)}>
          <div className={clsx('fx gap-4', Style.icons)}>
            <VideoActions vertical videoId={candidate.video.id}/>
          </div>

          {!!candidate.video.videoTranscription && (
            <Traits
              className={clsx('p-2 fx-wrap-wrap fx-ai-center', Style.traits)}
              style={{ background: '#fff' }}
              items={candidate.video.videoTranscription.traits}
            />
          )}

        </div>
      </ViewMode>
    )
  }

  return (
    <div ref={containerRef} className={clsx('fx-1 fx fx-ai-center fx-jc-center fx-column', Style.vibesGuest)}>
      {/* <FakeHeader title="Vibes" hideTutorial/> */}
      <div
        className={clsx('fx-1 fx fx-ai-center fx-jc-center fx-column w-100')}
      >
        {isLoading
          ? <EEmptyVideoVariant.Vibe/>
          : !candidate
            ? <EmptyVibes/>
            : (
              <div
                className={Style.vibesWrapperGuest}
                style={vibeWrapperStyle}
              >
                {renderVibe()}
              </div>
            )}
      </div>
    </div>
  )
}
