import { ICampaignModel } from 'src/interfaces'
import { convertEquivalentSalaryRate, formatNumber } from './helpers.utils'

export const CampaignUtils = new (
  class {
    getSalaryTxt(
      campaign: ICampaignModel,
      options?: { noRate?: boolean },
      defaultTxt = ''
    ): string {
      if (!campaign?.salaryValue) {
        return defaultTxt
      }
      const salaryValue = campaign.salaryValue as {
        min?: number
        max?: number
      }
      const salaryRate = convertEquivalentSalaryRate(campaign.salaryRate)
      const salary = [salaryValue.min, salaryValue.max].filter(Boolean) as number[]
      if (!salary.length) {
        return defaultTxt
      }
      return salary.map((s) => `$${formatNumber(s)}`).join(' - ') + (options?.noRate ? '' : ` / ${salaryRate}`)
    }
  }
)()
