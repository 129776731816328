import clsx from 'clsx'
import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs'
import { FakeHeader } from 'src/components'
import { useAppDispatch, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { EAuthRoutes } from 'src/modules/auth/routes.enum'
import { MyJobGuessOnboarding } from 'src/modules/onboarding/components/my-jobs/guess-index'
import { generate } from 'src/router'
import { GuestOnboardingService, OverlayService } from 'src/services'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { setLayoutAside, setLayoutHeader } from 'src/store/actions'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

export const PublicGuestOnboarding: FC = () => {
  const dispatch = useAppDispatch()
  const router = useHistory()
  const showedOnboarding = useBehaviorMapper(GuestOnboardingService.showedOnboarding$)
  const unsubscribe$ = useUnsubscribe()

  useEffect(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutHeader(false))

    return () => {
      dispatch(setLayoutHeader(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (showedOnboarding) return

    OverlayService.setOverlay({
      open: true,
      content: <MyJobGuessOnboarding/>,
      closeStyle: { display: 'none' },
      overlayBackground: 'rgba(0 0 0 / 80%)',
      onClose: () => {
        PopupTourService.show()
        GuestOnboardingService.showOnboarding()
      },
      isHiddenBackdropFilter: true
    })
  }, [showedOnboarding])

  const handleCreateJobClicked = useCallback(() => {
    router.push(generate(EAuthRoutes.SIGN_IN))
  }, [router])

  useEffect(() => {
    GuestOnboardingCreateJobTourService.completed$
      .pipe(
        takeUntil(unsubscribe$),
        filter(completed => completed),
        distinctUntilChanged()
      )
      .subscribe(() => {
        PopupTourService.showVVCPopup()
      })
  }, [unsubscribe$])

  return (
    <div className={clsx(Style.containerGuest, Style.height100)}>
      <FakeHeader showLogo title="My Jobs"/>
      <div className={clsx(Style.container, Style.height100)}>
        <EmptyJob
          tab={0}
          withBanner={false}
          onCreateJobClick={handleCreateJobClicked}
        />
      </div>
    </div>
  )
}
