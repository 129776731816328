import { useMemo } from 'react'
import { ICampaignModel } from 'src/interfaces'
import { CampaignUtils, convertEquivalentSalaryRate } from 'src/utils'

const LOCATION_TYPE = {
  HYBRID: 'Hybrid',
  ON_SITE: 'Onsite',
  REMOTE: 'Remote'
}

type TLocationType = keyof typeof LOCATION_TYPE

export const useJobTransformedData = (job?: ICampaignModel) => {
  const parsedData = useMemo(() => {
    if (!job) {
      return {
        salary: '',
        salaryRate: '',
        location: '',
        locationType: '',
        companyLogoUrl: '',
        companyName: ''
      }
    }

    const locationType = LOCATION_TYPE?.[job.locationType as TLocationType] || ''

    return {
      salary: CampaignUtils.getSalaryTxt(job, { noRate: true }),
      salaryRate: job?.salaryValue ? ` / ${convertEquivalentSalaryRate(job?.salaryRate)}` : '',
      location: job.location ? `${job.location}` : '',
      locationType,
      companyLogoUrl: job?.logoUrl || '',
      companyName: job?.companyName || ''
    }
  }, [job])

  return parsedData
}
