import { ChangeEventHandler, ComponentProps, FC, useCallback, useState } from 'react'
import { PasswordApi } from 'src/api'
import { NotAllowAuthenticated } from 'src/components'
import { EventUtils, getApiErrorMessage } from 'src/utils'
import { Form } from './form'
import Style from './style.module.scss'
import { Success } from './success'

interface IForgotPasswordForm {
  email: string
}

export const ForgotPassword: FC = () => {
  const [formValues, setFormValues] = useState<IForgotPasswordForm>({ email: '' })
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    setError('')
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [])

  const onSubmit = useCallback<Required<ComponentProps<'form'>>['onSubmit']>(async (e) => {
    EventUtils.preventDefault(e)

    try {
      await PasswordApi.forgotPasswordRequest(formValues)
      setIsSuccess(true)
    } catch (error) {
      setError(getApiErrorMessage(error))
    }
  }, [formValues])

  return (
    <NotAllowAuthenticated>
      <div className={Style.forgotPassword}>
        {isSuccess
          ? <Success email={formValues.email} onSubmit={onSubmit}/>
          : (
            <Form
              email={formValues.email}
              error={error}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          )}
      </div>
    </NotAllowAuthenticated>
  )
}
