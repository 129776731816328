export enum ETrackingEvent {
  INPUT_EMAIL = 'input_email',
  INPUT_SIGN_IN_PSWD = 'input_password',
  ERROR_EMAIL_DOMAIN = 'error_email_domain',
  ERROR_PSWD_BAD = 'error_pswd_bad',
  BTN_SIGNIN = 'btn_signin',
  BTN_FORGOT_PSWD = 'btn_forgot_pswd',
  BTN_SIGNUP = 'btn_signup',
  INPUT_NAME = 'input_name',

  BTN_RESEND_VERIFY_EMAIL = 'btn_resend_verify_email',
  BTN_PSWD_SETUP = 'btn_pswd_setup',
  INPUT_PSWD = 'input_pswd',
  INPUT_PSWD_CONFIRM = 'input_pswd_confirm',
  ERROR_PSWD_MISMATCH = 'error_pswd_mismatch',
  BTN_PSWD_NEXT = 'btn_pswd_next',
  CHKBOX_ROLES = 'chkbox_roles',
  INPUT_ROLES_COMMENT = 'input_roles_comment',
  BTN_ROLES_NEXT = 'btn_roles_next',
  INPUT_FIRST_NAME = 'input_first_name',
  INPUT_LAST_NAME = 'input_last_name',
  INPUT_COMPANY = 'input_company',
  INPUT_COMPANY_URL = 'input_company_url',
  CHKBOX_SPAM = 'chkbox_spam',
  BTN_INFO_NEXT = 'btn_info_next',

  INPUT_PSWD_NEW = 'input_pswd_new',
  BTN_SEND_RESET_PSWD_LINK = 'btn_send_reset_pswd_link',
  BTN_RESEND_RESET_PSWD_LINK = 'btn_resend_reset_pswd_link',
  BTN_RESET_PSWD = 'btn_reset_pswd',
  BTN_CHANGE_PSWD = 'btn_change_pswd',

  BTN_FLAG = 'btn_flag',
  BTN_INTRODUCE = 'btn_introduce',

  BTN_VIDEO_SKIP = 'btn_video_skip',
  BTN_VIDEO_TRASH = 'btn_video_trash',
  BTN_VIDEO_SUPERLIKE = 'btn_video_superlike',
  BTN_VIDEO_LIKE = 'btn_video_like',
  BTN_VIDEO_INVITE_APPLY = 'btn_video_invite_apply',

  BTN_VIBES_SCREEN = 'btn_vibes_screen',
  BTN_LIKES_SCREEN = 'btn_likes_screen',
  BTN_INTROS_SCREEN = 'btn_intros_screen',
  BTN_SKIPS_SCREEN = 'btn_skips_screen',
  BTN_CAMPAIGN_SCREEN = 'btn_campaign_screen',
  BTN_MY_JOBS_SCREEN = 'btn_my_jobs',
  BTN_OPTIONS_MENU = 'btn_options_menu',
  BTN_OPTIONS_PROFILE = 'btn_options_profile',
  BTN_OPTIONS_PAYMENTS = 'btn_options_payments',
  BTN_OPTIONS_INVITE = 'btn_options_invite',
  BTN_OPTIONS_SIGNOUT = 'btn_options_signout',
  BTN_EDIT_PROFILE = 'btn_edit_profile',
  BTN_STORE = 'btn_store',
  BTN_HELP = 'btn_help',
  SELECT_ROLE = 'select_role',

  BTN_STEP1 = 'btn_step1',
  BTN_STEP2 = 'btn_step3',
  BTN_STEP3 = 'btn_step2',
  BTN_STEP4 = 'btn_step4',
  BTN_STEP_HIDE = 'btn_step_hide',

  BTN_VIDEO_SHARE = 'btn_video_share',
  INPUT_SHARE_EMAIL = 'input_share_email',
  INPUT_SHARE_COMMENT = 'input_share_comment',

  BTN_CREATE_ACCOUNT = 'btn_create_account',
  INPUT_CANDIDATE_COMMENT = 'input_candidate_comment',
  BTN_CANDIDATE_LIKE = 'btn_candidate_like',
  BTN_CANDIDATE_DISLIKE = 'btn_candidate_dislike',
  BTN_COMMENT_SEND = 'btn_comment_send',
  BTN_INFO_SUBMIT = 'btn_info_submit',

  BTN_PUBLIC_PROFILE = 'btn_public_profile',
  BTN_BASIC_INFO = 'btn_basic_info',
  BTN_SECURITY = 'btn_security',
  BTN_NOTIFICATION_PREFERENCE = 'btn_notification_preference',
  BTN_PROFILE_PIC_CHANGE = 'btn_profile_pic_change',
  BTN_PROFILE_VIDEO_UPLOAD = 'btn_profile_video_upload',
  BTN_PROFILE_VIDEO_CHANGE = 'btn_profile_video_change',
  BTN_PROFILE_VIDEO_DELETE = 'btn_profile_video_delete',
  BTN_CANCEL = 'btn_cancel',
  BTN_UPDATE = 'btn_update',
  INPUT_WEBSITE = 'input_website',
  INPUT_CALENDLY = 'input_calendly',
  INPUT_COOL_FACTOR = 'input_cool_factor',
  INPUT_USER_NAME = 'input_user_name',

  INPUT_PSWD_CURRENT = 'input_pswd_current',

  BTN_INVITE = 'btn_invite',
  BTN_VIEW_INVOICE = 'btn_view_invoice',
  BTN_BUY_1 = 'btn_buy_1',
  BTN_BUY_4 = 'btn_buy_4',
  BTN_BUY_20 = 'btn_buy_20',
  BTN_CREDIT_DEC = 'btn_credit_dec',
  BTN_CREDIT_INC = 'btn_credit_inc',
  BTN_PAY = 'btn_pay',
  BTN_FINISH = 'btn_finish',
  INPUT_CC = 'input_cc',
  INPUT_CC_EXP = 'input_cc_exp',
  INPUT_CC_CVV = 'input_cc_cvv',

  BTN_COPY_LINK = 'btn_copy_link',

  VIDEO_PLAY = 'video_play',
  VIDEO_PAUSE = 'video_pause',
  VIDEO_REPLAY = 'video_replay',
  VIDEO_SCRUB = 'video_scrub',
  VIDEO_SEEK_TO = 'video_seek_to',

  BTN_VIDEO_INFO = 'btn_video_info',
  BTN_DETAIL_PROFILE = 'btn_detail_profile',
  BTN_DETAIL_COMMENTS = 'btn_detail_comments',
  INPUT_PRIVATE_NOTES = 'input_private_notes',

  // CAMPAIGN EVENTS
  CAMPAIGN_BTN_CREATE_JOBS = 'btn_jobs_create',
  CAMPAIGN_BTN_JOB_SELECT = 'btn_jobs_select',
  CAMPAIGN_BTN_JOB_EDIT = 'btn_jobs_edit',
  CAMPAIGN_BTN_JOBS_COPY_URL = 'btn_jobs_copy_url',
  CAMPAIGN_BTN_VIDEO_INFO = 'btn_video_info',
  CAMPAIGN_BTN_VIDEO_SHARE = 'btn_video_share',
  CAMPAIGN_BTN_VIDEO_SOFTSKILLS = 'btn_video_softskills',
  CAMPAIGN_BTN_VIDEO_INFO_SOFTSKILLS = 'btn_video_info_softskills',
  CAMPAIGN_BTN_VIDEO_INFO_EXPERIENCE = 'btn_video_info_experience',
  CAMPAIGN_BTN_VIDEO_INFO_EDUCATION = 'btn_video_info_education',
  CAMPAIGN_SELECT_JOBS_VIEW = 'select_jobs_view',
  CAMPAIGN_BTN_SOFTSKILLS_FILTER = 'btn_softskills_filter',
  CHECKBOX_MOTIVATOR = 'chkbox_softskill_motivator',
  CHECKBOX_CORE = 'chkbox_softskill_core',
  BTN_SOFTSKILLS_DEFAULT = 'btn_softskills_default',
  BTN_SOFTSKILLS_CANCEL = 'btn_softskills_cancel',
  BTN_SOFTSKILLS_SUBMIT = 'btn_softskills_submit',
  CAMPAIGN_BTN_COMPANY_LOGO_UPLOAD = 'btn_company_logo_upload',
  CAMPAIGN_INPUT_COMPANY_NAME = 'input_company',
  CAMPAIGN_INPUT_COMPANY_URL = 'input_company_url',
  CAMPAIGN_COMPANY_VIDEO_CREATE = 'btn_company_video_create',
  CAMPAIGN_COMPANY_VIDEO_UPLOAD = 'btn_company_video_upload',
  CAMPAIGN_COMPANY_JOB_NEXT = 'btn_jobs_next',
  CAMPAIGN_COMPANY_JOB_CANCEL = 'btn_jobs_cancel',
  CAMPAIGN_COMPANY_JOB_PREVIEW = 'btn_jobs_preview',
  CAMPAIGN_INPUT_JOB_NAME = 'input_jobs_name',
  CAMPAIGN_INPUT_JOB_TITLE = 'input_jobs_title',
  SELECT_JOB_SALARY_RANGE = 'select_jobs_salary_range',
  SELECT_JOB_SALARY_ATM = 'select_job_salary_amt',
  SELECT_JOB_SALARY_RATE = 'select_jobs_salary_rate',
  CAMPAIGN_INPUT_JOB_LOCATION = 'input_jobs_location',
  CAMPAIGN_CHECKBOX_JOB_REMOTE = 'chkbox_jobs_remote',
  CAMPAIGN_BTN_HM_VIDEO_CREATE = 'btn_hm_video_create',
  CAMPAIGN_BTN_HM_VIDEO_UPLOAD = 'btn_hm_video_upload',
  CAMPAIGN_BTN_HM_VIDEO_PROFILE = 'btn_hm_video_profile',
  CAMPAIGN_SELECT_JOB_QUESTION = 'select_jobs_question',
  CAMPAIGN_CHECKBOX_JOB_RESUME = 'chkbox_jobs_resume',
  CAMPAIGN_CHECKBOX_JOB_LINKEDIN = 'chkbox_jobs_linkedin',
  CAMPAIGN_CHECKBOX_JOB_ZIP = 'chkbox_jobs_zip',
  CAMPAIGN_INPUT_JOB_DESCRIPTION = 'input_jobs_description',
  CAMPAIGN_BTN_JOB_GEN_SUMMARY = 'btn_jobs_gen_summary',
  CAMPAIGN_INPUT_JOB_SUMMARY = 'input_jobs_summary',
  CAMPAIGN_INPUT_JOB_ATTACH_FILE = 'btn_jobs_attach_file',
  CAMPAIGN_INPUT_JOB_MARKETPLACE = 'chkbox_jobs_marketplace',
  CAMPAIGN_COMPANY_JOB_SUBMIT = 'btn_jobs_submit',
  CAMPAIGN_SELECT_JOBS_SORT = 'select_jobs_sort',
  CAMPAIGN_JOBS_DELETE = 'btn_jobs_delete',
  CAMPAIGN_JOBS_DETAIL = 'btn_jobs_detail',
  CAMPAIGN_INPUT_JOBS_SEARCH = 'input_jobs_search',
  BTN_NOTIFICATIONS = 'btn_notifications',

  RECORDING_BTN_REC_SAMPLES = 'btn_rec_samples',
  RECORDING_BTN_REC_TIPS = 'btn_rec_tips',
  RECORDING_BTN_REC_BKGD_IMAGE = 'btn_rec_bkgd_image',
  RECORDING_BTN_REC_TELEPROMPTER = 'btn_rec_teleprompter',
  RECORDING_BTN_REC_UPLOAD = 'btn_rec_upload',

  BTN_VIBE_TALENT = 'btn_vibe_talent'
}

// Options:
// btn_my_jobs
// btn_jobs_create
// btn_jobs_select
// btn_jobs_edit
// btn_jobs_copy_url
// btn_video_info
// btn_video_share
// btn_video_softskills
// btn_video_info_softskills
// btn_video_info_experience
// btn_video_info_education
// btn_video_play
// btn_video_pause
// select_jobs_view
// btn_softskills_filter
// chkbox_softskill_core
// chkbox_softskill_motivator
// btn_softskills_default
// btn_softskills_cancel
// btn_softskills_submit
