import { IFeedbackModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'

export class FeedbackApi {
  static readonly _prefix = '/feedback'

  static create(payload: IFeedbackModel): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${FeedbackApi._prefix}`, payload)
  }
}
