
import VibeCard from '../vibe-card'
import Style from './style.module.scss'

type Props = { items: any[] }

const GuestGridView = (props: Props) => {
  return (
    <div className={Style.gridViewWrapper}>

      <VibeCard/>

    </div>
  )
}

export default GuestGridView
