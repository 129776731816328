
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { VideoPlayer } from 'src/components'
import { EMessage } from 'src/enums'
import { useAppDispatch, useAppSelector, useUnsubscribe } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getIsAuthenticated } from 'src/store/selectors'
import { getApiErrorMessage, getFullName, getVideoSource } from 'src/utils'
import VideoActions from '../video-actions'
import ViewMode from '../view-mode'
import Style from './style.module.scss'

const videoPlayerSx = {
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  aspectRatio: '323 / 420',
  height: 'unset',
  backgroundColor: 'black'
}

const VibeCard = () => {
  const location = useLocation<{from: string}>()
  const dispatch = useAppDispatch()

  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  const [candidate, setCandidate] = useState<ICandidateModel>()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/vibes')
    }
  }, [isAuthenticated, history])

  useEffect(() => {
    if (location.state?.from) {
      history.push(location.state.from)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.from])

  const loadVibes = () => {
    const promise = CandidateApi.getPublicOne()

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe(({ data }) => {
        console.log('data', data)

        setCandidate(data)
      })
  }

  useEffect(() => {
    loadVibes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWatchedVideo = async (videoId: number, playing: boolean) => {
    try {
      console.log('playing', videoId, playing)
    } catch (error) {
      console.log({ error })
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'NETWORK ERROR'
      })
    }
  }

  if (!candidate) return <></>

  const { firstName, lastName } = candidate

  return (
    <div className={Style.vibeCardWrapper}>
      <ViewMode viewMode="grid">
        <div className={Style.videoWrapper}>
          <VideoPlayer
            id="video"
            className={Style.videoPlayer}
            videoId={candidate.video?.id}
            tracks={candidate.video?.tracks}
            trackingEvent
            isPlay={false}
            image={candidate.video?.urlVideoImageThumbnail}
            animatedImage={candidate.video?.urlVideoAnimatedImage}
            url={getVideoSource(candidate.video)}
            onPlayingChange={(playing) => handleWatchedVideo(candidate.video.id, playing)}
            mimeType={candidate.video?.internalSourceMetadata?.mimeType}
            style={videoPlayerSx}
          />
        </div>
        <div className="px-4">
          <span className={Style.candidateName}>@{getFullName({ firstName, lastName })}</span>
        </div>
      </ViewMode>

      <div className="fx flex fx-ai-center fx-jc-flex-start px-4 py-5">
        <VideoActions videoId={candidate.video?.id}/>
      </div>
    </div>
  )
}

export default VibeCard
