import { styled, TextareaAutosize } from '@mui/material'
import { Box } from '@mui/system'

export const TextareaCustom = styled<typeof TextareaAutosize>(
  TextareaAutosize,
  {
    label: 'TextAreaCustom',
    shouldForwardProp: (prop) => prop !== 'disableResize'
  }
)<{ disableResize?: boolean }>(
  ({ theme, disableResize }) => `
  border: 1px solid ${theme.colors['--color-neutral-theme-200']};
  border-radius: 16px;
  color: ${theme.colors['--color-neutral-theme-700']};
  padding: ${theme.spacing(1.75, 2)};
  font-family: inherit;
  font-size: inherit;
  outline: unset;
  width: 100%;

  ${
  disableResize &&
    `
    resize: none;
  `
}

  &::placeholder {
    color: ${theme.colors['--color-neutral-theme-300']};
  }

  &:hover:not(:focus-within):not(.Mui-disabled):not(.Mui-error) {
    border-color: transparent; /* remove the border's colour */
    box-shadow: 0 0 0 1px ${
  theme.colors['--color-neutral-theme-200']
}; /* emulate the border */
  }

  :focus-within:not(.Mui-error) {
    border-color: transparent; /* remove the border's colour */
    box-shadow: 0 0 0 1px ${
  theme.colors['--color-neutral-theme-300']
}; /* emulate the border */
  }

  &.Mui-disabled {
    border-color: transparent;
    background-color: ${theme.colors['--color-neutral-theme-50']};
    box-shadow: 0 0 0 1px ${
  theme.colors['--color-neutral-theme-200']
}; /* emulate the border */
  }

  &.Mui-error {
    box-shadow: 0 0 0 1px ${
  theme.colors['--color-negative-500']
}; /* emulate the border */
  }
`
)

export const TextareaWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const TextareaCounter = styled('span')`
  position: absolute;
  bottom: 14px;
  right: 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors['--color-neutral-theme-300']};
`
