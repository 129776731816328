import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Tabs } from 'src/components'

import { useQueryParams } from 'src/hooks'
import { ITab } from 'src/interfaces'
import GuestGridView from '../../components/grid-view/guest-index'
import { GuestVibes as GuestSwipeView } from '../../components/list/guest-index'
import Sorting from '../../components/sorting/Sorting'
import Style from './style.module.scss'

const viewType = {
  SWIPE_VIEW: 'Grid View',
  GRID_VIEW: 'Swipe View'
}

export const tabs: ITab[] = [
  { title: viewType.SWIPE_VIEW },
  { title: viewType.GRID_VIEW }
]

const VibesGuest = () => {
  const history = useHistory()
  const { tab } = useQueryParams()

  const [_tab, setTab] = useState<number>(0)

  useEffect(() => {
    if (tab) {
      setTab(+tab)
    }
  }, [tab])

  const handleSetTab = (newTab: number) => {
    setTab(newTab)
    if (+(tab || 0) !== newTab) {
      history.replace({ search: `?tab=${newTab}` })
    }
  }

  const renderView = useCallback(() => {
    if (_tab === 0) {
      return <GuestGridView items={[]}/>
    }
    if (_tab === 1) {
      return <GuestSwipeView/>
    }
    throw new Error('Unknown tab')
  }, [_tab])

  return (
    <div className={Style.container}>

      <div className={Style.pageHeader}>
        <div className={Style.title}>
          Vibe Talent <span className={Style.count}>(12)</span>
        </div>
        <div className={Style.header}>
          <div className={Style.header_tab}>
            <Tabs tab={_tab} tabs={tabs} setTab={handleSetTab}/>
          </div>
          <div className={Style.sorting}>
            {/* tin-todo: Add sort */}
            <Sorting/>
          </div>
        </div>
      </div>

      <div className={Style.mainContent}>{renderView()}</div>
    </div>
  )
}

export default VibesGuest
