import { PropsWithChildren } from 'react'

type ViewModeProps ={
  viewMode: string
} & PropsWithChildren

const ViewMode = (props: ViewModeProps) => {
  const { viewMode, children } = props

  const getClassName = (mode: string) => {
    let classes = ''

    if (mode === 'grid') {
      classes = 'relative'
    }
    if (mode === 'swipe') {
      classes = 'relative fx fx-ai-center fx-jc-center'
    }

    return classes
  }

  return (
    <div className={getClassName(viewMode)}>
      {children}
    </div>
  )
}

export default ViewMode
